import * as Cookie from '@integrabeauty/cookie';

interface MetaInit {
  customer: {
    birthday: string;
    default_address: {
      address1: string;
      address2: string;
      city: string;
      company: string;
      country: string;
      country_code: string;
      country_name: string;
      customer_id: number;
      default: boolean;
      first_name: string;
      id: number;
      last_name: string;
      name: string;
      phone: string;
      province: string;
      province_code: string;
      zip: string;
    };
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    phone: string;
  };

  listener: (event: Event)=> void;
  market: string;
  pixel_id: string;
  queue: Event[];
}

declare global {
  /**
   * This is created in liquid
   */
  // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
  var meta_init: MetaInit;
}

/**
 * Meta defines this as "When a person enters the checkout flow prior to completing the checkout
 * flow."
 *
 * This event interacts with Meta Commerce. The product ids transmitted should match up with the ids
 * of products in the synced catalogs.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference
 * @see https://developers.facebook.com/docs/meta-pixel/implementation/pixel-for-official-events#initiatecheckout
 */
function onCheckoutStarted(event: WindowEventMap['checkout-started']) {
  const cart = event.detail.cart;

  fbq('track', 'InitiateCheckout', {
    content_ids: cart.items.map(item =>
      buildCatalogId(item.product_id, item.variant_id)),
    contents: cart.items.map(item => ({
      id: buildCatalogId(item.product_id, item.variant_id),
      quantity: item.quantity
    })),
    currency: cart.currency,
    num_items: cart.item_count.toString(),
    value: cart.total_price / 100
  });
}

/**
 * This must match the configuration for synchronizing with fb commerce
 */
function buildCatalogId(productId: number, variantId: number) {
  return `shopify_${productId}_${variantId}`;
}

/**
 * > A submission of information by a customer with the understanding that they may be contacted at
 * > a later date by your business. For example, submitting a form or signing up for a trial.
 *
 * Do not include any PII in the events.
 *
 * @see https://www.facebook.com/business/help/402791146561655?id=1205376682832142
 */
function onAttentiveLeadCaptured(event: WindowEventMap['attentive-lead-captured']) {
  if (event.detail.action === 'EMAIL_LEAD') {
    fbq('track', 'CompleteRegistration', {
      registration_type: 'attentive-email-lead'
    });
  } else if (event.detail.action === 'LEAD') {
    fbq('track', 'CompleteRegistration', {
      registration_type: 'attentive-lead'
    });
  }

  // Capture PII for enhanced matching.

  const email = event.detail.email;
  if (isValidEmail(email)) {
    try {
      localStorage.setItem('meta_attentive_email', email);
    } catch (error) {
      // ignore
    }
  }

  const phone = event.detail.phone;
  if (phone) {
    try {
      localStorage.setItem('meta_attentive_phone', email);
    } catch (error) {
      // ignore
    }
  }
}

/**
 * When the user starts a Gladly conversation, send a Contact event to Facebook.
 *
 * The Facebook docs say a Contact event should be sent "When a person initiates contact with your
 * business via telephone, SMS, email, chat, etc."
 *
 * All of the contact event properties are optional. It is not clear what we should be sending. I
 * invented a custom field to represent the method involved in the contact.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference
 */
function onGladlyConversationStarted(_event: WindowEventMap['gladly-conversation-started']) {
  fbq('track', 'Contact', { custom_method: 'gladly-chat' });
}

/**
 * The Meta documentation explicitly mentions this specific use an example of a contact event:
 *
 * > When a person initiates contact with your business via telephone, SMS, email, chat, etc.
 * > A person submits a question about a product.
 *
 * We are not sending the body because the question content is not used for analysis or targeting
 * and there is a risk of PII being present.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference/
 */
function onYotpoQuestionCreated(_event: WindowEventMap['yotpo-question-created']) {
  fbq('track', 'Contact', { custom_method: 'yotpo-question-create-form' });
}

/**
 * Handle a review submitted event. If the review was submitted successfully, send a contact event.
 *
 * Do not include any PII in the event.
 */
function onYotpoReviewSubmitted(event: WindowEventMap['yotpo-review-submitted']) {
  if (!event.detail.error_message) {
    fbq('track', 'Contact', { custom_method: 'yotpo-product-review-submitted' });

    const email = event.detail.email;
    if (isValidEmail(email)) {
      try {
        localStorage.setItem('meta_yotpo_email', email);
      } catch (error) {}
    }
  }
}

/**
 * When a product variant is viewed, send a ViewContent event to Facebook.
 *
 * The content id must align with the content id in the Facebook Commerce Center catalogue and all
 * other events that send product-related data to Facebook.
 *
 * Relevant fields are content_ids, content_category, content_name, content_type, contents,
 * currency, and value. if using dynamic ads then content_type and contents or content_ids are
 * required. We do not need to send both content_ids and contents. There is no real point to sending
 * contents here because we are not sending other properties such as quantity.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference#object-properties
 */
function onProductVariantViewed(event: WindowEventMap['product-variant-viewed']) {
  if (!Number.isInteger(event.detail.product_id)) {
    return;
  }

  const contentId = buildCatalogId(event.detail.product_id, event.detail.variant_id);

  fbq('track', 'ViewContent', {
    content_category: event.detail.type,
    content_name: event.detail.product_title,
    content_ids: contentId ? [contentId] : undefined,
    content_type: 'product',
    currency: event.detail.currency,
    value: event.detail.price / 100
  });
}

/**
 * When debugging this event, beware that the Meta Pixel Chrome extension is sometimes incorrect.
 * Always debug using the pixel debugging tool provided by Meta. The extension is wrong.
 *
 * Do not include any PII in the event.
 */
function onCartUpdated(event: WindowEventMap['cart-updated']) {
  for (const variant of event.detail.variants_added) {
    fbq('track', 'AddToCart', {
      content_category: variant.product_type,
      content_name: variant.product_title,
      content_type: 'product',
      content_ids: [buildCatalogId(variant.product_id, variant.variant_id)],
      currency: event.detail.cart.currency,
      value: variant.price / 100
    });
  }
}

/**
 * Handle an Back In Stock form submitted event. If successful, forward a completed registration
 * event to the Facebook pixel.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference
 * @see https://www.facebook.com/business/help/402791146561655?id=1205376682832142
 */
function onBISFormSubmitted(event: WindowEventMap['bis-form-submitted']) {
  if (!event.detail.success) {
    return;
  }

  // none of these fields are documented for this event, and some are custom. this is all invented.
  // all facebook examples only show tracking an event name with no properties at all. i believe it
  // is harmless to send over these non-standard properties.

  // optional standard properties: content_name, currency, status, value

  fbq('track', 'CompleteRegistration', {
    content_name: 'back-in-stock',
    custom_product_id: event.detail.product_id,
    custom_product_title: event.detail.product_title,
    custom_variant_id: event.detail.variant_id,
    custom_variant_sku: event.detail.sku,
    custom_variant_title: event.detail.variant_title,
    registration_type: 'back-in-stock',
    status: true
  });

  const email = event.detail.email;
  if (isValidEmail(email)) {
    try {
      localStorage.setItem('meta_bis_email', email);
    } catch (error) {
      // ignore
    }
  }
}

/**
 * When a user fills out the bottom of the page form with email address and phone number, footer.js
 * fires a subscription attempted event. In response, notify Facebook that the user has completed a
 * registration.
 *
 * We cannot send personally identifiable information in this event within its properties, even
 * though we possibly just learned of the PII for the first time.
 *
 * None of the fields for the standard properties for the CompleteRegistration event are clearly
 * documented. I made some guesses about the properties to use. Unclear if they are correct.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference
 */
function onMarketingSubscribed(event: WindowEventMap['marketing-subscribed']) {
  if (!event.detail.success) {
    return;
  }

  fbq('track', 'CompleteRegistration', {
    content_name: 'email-phone-signup',
    registration_type: 'email-phone-signup',
    status: true
  });

  const email = event.detail.email;
  if (isValidEmail(email)) {
    try {
      localStorage.setItem('meta_marketing_subscribe_email', email);
    } catch (error) {}
  }
}

/**
 * When the user selects a different variant for a product, send a CustomizeProduct event to
 * Facebook.
 *
 * The documentation shows that all properties are optional. It is not clear what we are supposed to
 * send to Facebook. As a guess, send the new product and variant that was selected.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference
 */
function onProductVariantOptionChanged(event: WindowEventMap['product-variant-option-changed']) {
  if (!Number.isInteger(event.detail.id) || !Number.isInteger(event.detail.selected_variant_id)) {
    return;
  }

  fbq('track', 'CustomizeProduct', {
    content_ids: [buildCatalogId(event.detail.id, event.detail.selected_variant_id)],
    content_type: 'product'
  });
}

/**
 * When a user searches our products using our on-site search, send a search event to Facebook.
 *
 * Do not include any PII in the event.
 *
 * @see https://developers.facebook.com/docs/meta-pixel/reference
 */
function onSearched(event: WindowEventMap['searched']) {
  const query = event.detail.query?.trim();
  if (query) {
    fbq('track', 'Search', { search_string: query });
  }
}

/**
 * Handles a Typeform submit event. Send a contact event to Meta.
 */
function onTypeformSubmitted(event: WindowEventMap['typeform-submitted']) {
  if (!event.detail.error_message) {
    fbq('track', 'Contact', { custom_method: 'typeform-survey-submitted' });
  }
}

function isValidEmail(email: string) {
  // eslint-disable-next-line @stylistic/max-len
  return /^[\w!#\$%&\'\*\+\/\=\?\^`\{\|\}~\-]+(:?\.[\w!#\$%&\'\*\+\/\=\?\^`\{\|\}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i.test(email);
}

interface EnhancedMatchingData {
  /**
   * Lowercase two letter country code
   *
   * @example "us"
   */
  country: string;

  /**
   * Lowercase with any spaces removed
   */
  ct: string;

  /**
   * Digits only with birth year, month, then day
   */
  db: string;

  /**
   * Unhashed lowercase or hashed SHA-256
   */
  em: string;

  /**
   * Any unique ID from the advertiser, such as loyalty membership ID, user ID, and external
   * cookie ID.
   */
  external_id: string;

  /**
   * First name
   */
  fn: string;

  /**
   * Gender
   */
  ge: 'f' | 'm';

  /**
   * Last name
   */
  ln: string;

  /**
   * Digits only including country code and area code
   *
   * @example 12223334444
   */
  ph: string;

  /**
   * Lowercase two-letter state or province code
   */
  st: string;

  /**
   * Zip or postal code
   */
  zp: string;
}

function buildData() {
  const customer = meta_init.customer;

  /**
   * See https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching
   */
  const data: Partial<EnhancedMatchingData> = {};

  if (isValidEmail(customer.email)) {
    data.em = customer.email.toLowerCase();
  }

  if (!data.em) {
    try {
      const email = localStorage.getItem('meta_attentive_email');
      if (isValidEmail(email)) {
        data.em = email.toLowerCase();
      }
    } catch (error) {}
  }

  if (!data.em) {
    try {
      const email = localStorage.getItem('meta_marketing_subscribe_email');
      if (isValidEmail(email)) {
        data.em = email.toLowerCase();
      }
    } catch (error) {}
  }

  if (!data.em) {
    try {
      const email = localStorage.getItem('meta_bis_email');
      if (isValidEmail(email)) {
        data.em = email.toLowerCase();
      }
    } catch (error) {}
  }

  if (!data.em) {
    try {
      const email = localStorage.getItem('meta_yotpo_email');
      if (isValidEmail(email)) {
        data.em = email.toLowerCase();
      }
    } catch (error) {}
  }

  if (customer.phone) {
    let phone = customer.phone.replace(/[^0-9]/g, '');
    phone = phone.length === 10 ? '1' + phone : phone;
    data.ph = phone;
  }

  if (!data.ph) {
    try {
      let phone = localStorage.getItem('meta_attentive_phone');
      if (phone) {
        phone = phone.replace(/[^0-9]/g, '');
        phone = phone.length === 10 ? '1' + phone : phone;
        data.ph = phone;
      }
    } catch (error) {}
  }

  if (customer.first_name) {
    data.fn = customer.first_name.trim().toLowerCase();
  }

  if (customer.last_name) {
    data.ln = customer.last_name.trim().toLowerCase();
  }

  const address = customer.default_address || <typeof customer['default_address']>{};

  if (address.city) {
    data.ct = address.city.replace(/\s/g, '').toLowerCase();
  }

  if (address.province_code?.length === 2) {
    data.st = address.province_code.toLowerCase();
  }

  if (address.zip) {
    data.zp = address.zip.trim().toLowerCase();
  }

  if (address.country_code?.length === 2) {
    data.country = address.country_code.toLowerCase();
  }

  if (Number.isInteger(customer.id)) {
    data.external_id = customer.id.toString();
  }

  return data;
}

/**
 * Return whether the visitor consents to tracking for marketing purposes.
 *
 * This does not check the DoNotTrack property because that was deprecated.
 *
 * @param marketHandle the value corresponding to liquid's localization.market.handle
 * @returns whether the visitor consents to tracking
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/globalPrivacyControl
 */
export function hasConsent(marketHandle: string) {
  let cookie;
  try {
    cookie = Cookie.read('OptanonConsent');
  } catch (error) {}

  const groups: Record<string, '0' | '1' | undefined> = {};
  if (cookie) {
    let params;
    try {
      params = new URLSearchParams(cookie);
    } catch (error) {}

    // The groups param contains a comma-separated list of tuples. Each tuple consists of a group
    // identifier and a true/false flag separated by a colon. For example, C0001:1,C0002:1.

    const tuples = params?.get('groups')?.split(',') || [];
    for (const tuple of tuples) {
      const [name, flag] = tuple.split(':');
      if (flag === '1' || flag === '0') {
        groups[name] = flag;
      }
    }
  }

  const ONETRUST_TARGETING_GROUP = 'C0004';
  const value = groups[ONETRUST_TARGETING_GROUP];

  if (value === '1') {
    return true;
  }

  if (value === '0') {
    return false;
  }

  if (navigator.globalPrivacyControl === true) {
    return false;
  }

  return marketHandle !== 'gb';
}

function main() {
  if (location.protocol !== 'https:' || location.hostname !== 'langehair.com') {
    return;
  }

  try {
    if (top !== window) {
      return;
    }
  } catch (error) {
    return;
  }

  fbq('consent', 'grant');

  if (!hasConsent(meta_init.market)) {
    if (meta_init.market === 'gb') {
      fbq('consent', 'revoke');
    } else {
      fbq('dataProcessingOptions', ['LDU'], 0, 0);
    }
  }

  const data = buildData();
  if (data && Object.keys(data).length > 0) {
    fbq('init', meta_init.pixel_id, data);
  } else {
    fbq('init', meta_init.pixel_id);
  }

  fbq('track', 'PageView');

  const listenerMap: Record<string, (event: any)=> void> = {
    'attentive-lead-captured': onAttentiveLeadCaptured,
    'cart-updated': onCartUpdated,
    'checkout-started': onCheckoutStarted,
    'gladly-conversation-started': onGladlyConversationStarted,
    'marketing-subscribed': onMarketingSubscribed,
    'bis-form-submitted': onBISFormSubmitted,
    'product-variant-option-changed': onProductVariantOptionChanged,
    'product-variant-viewed': onProductVariantViewed,
    'searched': onSearched,
    'typeform-submitted': onTypeformSubmitted,
    'yotpo-question-created': onYotpoQuestionCreated,
    'yotpo-review-submitted': onYotpoReviewSubmitted
  };

  // Unregister the loader listener from all events. This expects that the loader's list of event
  // types is properly in sync with the listener map.
  for (const eventType in listenerMap) {
    removeEventListener(eventType, meta_init.listener);
  }

  // Replay any prior events. We need an explicit try/catch per call here in case one call errs
  for (const event of meta_init.queue) {
    try {
      listenerMap[event?.type]?.(event);
    } catch (error) {
      console.warn(error);
    }
  }

  // Reset the queue (for idempotency and memory)
  meta_init.queue = [];

  // Register the new listeners
  for (const type in listenerMap) {
    addEventListener(type, listenerMap[type]);
  }
}

main();
